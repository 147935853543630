function isRULocale() {
  if (window.LOCALE && window.LOCALE.toLowerCase() === 'ru') {
    return true;
  }
  return false
}


export default [
  {
    "value": "Dateline Standard Time",
    "abbr": "DST",
    "offset": -12,
    "isdst": false,
    "text": isRULocale() ? '(UTC-12:00) Международная линия перемены дат Запад' : "(UTC-12:00) International Date Line West",
    "utc": ["Etc/GMT+12"]
  },
  {
    "value": "UTC-11",
    "abbr": "U",
    "offset": -11,
    "isdst": false,
    "text": isRULocale() ? '(UTC-11:00) Всемирное координированное время -11' : "(UTC-11:00) Coordinated Universal Time-11",
    "utc": ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"]
  },
  {
    "value": "Hawaiian Standard Time",
    "abbr": "HST",
    "offset": -10,
    "isdst": false,
    "text": isRULocale() ? "(UTC-10:00) Гаваи" : "(UTC-10:00) Hawaii",
    "utc": ["Etc/GMT+10", "Pacific/Honolulu", "Pacific/Johnston", "Pacific/Rarotonga", "Pacific/Tahiti"]
  },
  {
    "value": "Alaskan Standard Time",
    "abbr": "AKDT",
    "offset": -8,
    "isdst": true,
    "text": isRULocale() ?  "(UTC-09:00) Аляска" : "(UTC-09:00) Alaska",
    "utc": ["America/Anchorage", "America/Juneau", "America/Nome", "America/Sitka", "America/Yakutat"]
  },
  {
    "value": "Pacific Daylight Time",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": isRULocale() ? "(UTC-07:00) Тихоокеанское время (США и Канада)" : "(UTC-07:00) Pacific Time (US & Canada)",
    "utc": ["America/Dawson", "America/Los_Angeles", "America/Tijuana", "America/Vancouver", "America/Whitehorse"]
  },
  {
    "value": "Central America Standard Time",
    "abbr": "CAST",
    "offset": -6,
    "isdst": false,
    "text": isRULocale() ? "(UTC-06:00) Центральноамериканское Время" : "(UTC-06:00) Central America",
    "utc": [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ]
  },
  {
    "value": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": isRULocale() ? "(UTC-06:00) Центральное время (США и Канада)" : "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "value": "Venezuela Standard Time",
    "abbr": "VST",
    "offset": -4.5,
    "isdst": false,
    "text": isRULocale() ? "(UTC-04:30) Каракас" :  "(UTC-04:30) Caracas",
    "utc": ["America/Caracas"]
  },
  {
    "value": "Paraguay Standard Time",
    "abbr": "PYT",
    "offset": -4,
    "isdst": false,
    "text": isRULocale() ? "(UTC-04:00) Асунсьон" : "(UTC-04:00) Asuncion",
    "utc": ["America/Asuncion"]
  },
  {
    "value": "Atlantic Standard Time",
    "abbr": "ADT",
    "offset": -3,
    "isdst": true,
    "text":  isRULocale() ? "(UTC-04:00) Атлантическое время (Канада)" : "(UTC-04:00) Atlantic Time (Canada)",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    "value": "Newfoundland Standard Time",
    "abbr": "NDT",
    "offset": -2.5,
    "isdst": true,
    "text": isRULocale() ? "(UTC-03:30) Ньюфаундленд" : "(UTC-03:30) Newfoundland",
    "utc": ["America/St_Johns"]
  },
  {
    "value": "UTC-02",
    "abbr": "U",
    "offset": -2,
    "isdst": false,
    "text": isRULocale() ? "(UTC-02:00) Всемирное координированное время -02" : "(UTC-02:00) Coordinated Universal Time-02",
    "utc": ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"]
  },
  {
    "value": "Cape Verde Standard Time",
    "abbr": "CVST",
    "offset": -1,
    "isdst": false,
    "text": isRULocale() ? "(UTC-01:00) Кабо-Верде" : "(UTC-01:00) Cape Verde Is.",
    "utc": ["Atlantic/Cape_Verde", "Etc/GMT+1"]
  },
  {
    "value": "GMT Standard Time",
    "abbr": "GMT",
    "offset": 0,
    "isdst": false,
    "text": isRULocale() ? "(UTC) Эдинбург, Лондон" : "(UTC) Edinburgh, London",
    "utc": ["Europe/Isle_of_Man", "Europe/Guernsey", "Europe/Jersey", "Europe/London"]
  },
  {
    "value": "British Summer Time",
    "abbr": "BST",
    "offset": 1,
    "isdst": true,
    "text": isRULocale() ? "(UTC+01:00) Эдинбург, Лондон" : "(UTC+01:00) Edinburgh, London",
    "utc": ["Europe/Isle_of_Man", "Europe/Guernsey", "Europe/Jersey", "Europe/London"]
  },
  {
    "value": "GTB Standard Time",
    "abbr": "GDT",
    "offset": 2,
    "isdst": true,
    "text": isRULocale() ? "(UTC+02:00) Афины , Бухарест" : "(UTC+02:00) Athens, Bucharest",
    "utc": ["Asia/Nicosia", "Europe/Athens", "Europe/Bucharest", "Europe/Chisinau"]
  },
  {
    "value": "Middle East Standard Time",
    "abbr": "MEDT",
    "offset": 2,
    "isdst": true,
    "text": isRULocale() ? "(UTC+02:00) Бейрут" : "(UTC+02:00) Beirut",
    "utc": ["Asia/Beirut"]
  },
  {
    "value": "Moscow Standard Time",
    "abbr": "MSK",
    "offset": 3,
    "isdst": false,
    "text": isRULocale() ? "(UTC+03:00) Москва, Санкт-петербург, Волгоград, Минск" :  "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    "utc": ["Europe/Kirov", "Europe/Moscow", "Europe/Simferopol", "Europe/Volgograd", "Europe/Minsk"]
  },
  {
    "value": "Samara Time",
    "abbr": "SAMT",
    "offset": 4,
    "isdst": false,
    "text":  isRULocale() ? "(UTC+04:00) Самара, Ульяновск, Саратов" : "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    "utc": ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"]
  },
  {
    "value": "Georgian Standard Time",
    "abbr": "GET",
    "offset": 4,
    "isdst": false,
    "text": isRULocale() ? "(UTC+04:00) Тбилиси" : "(UTC+04:00) Tbilisi",
    "utc": ["Asia/Tbilisi"]
  },
  {
    "value": "Afghanistan Standard Time",
    "abbr": "AST",
    "offset": 4.5,
    "isdst": false,
    "text": isRULocale() ? "(UTC+04:30) Кабул" : "(UTC+04:30) Kabul",
    "utc": ["Asia/Kabul"]
  },
  {
    "value": "Yekaterinburg Time",
    "abbr": "YEKT",
    "offset": 5,
    "isdst": false,
    "text": isRULocale() ? "(UTC+05:00) Екатеринбург" : "(UTC+05:00) Yekaterinburg",
    "utc": ["Asia/Yekaterinburg"]
  },
  {
    "value": "India Standard Time",
    "abbr": "IST",
    "offset": 5.5,
    "isdst": false,
    "text": isRULocale() ? "(UTC+05:30) Ченнай , Калькутта , Мумбаи (Бомбей), Нью - Дели" : "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "utc": ["Asia/Kolkata"]
  },
  {
    "value": "Central Asia Standard Time",
    "abbr": "CAST",
    "offset": 6,
    "isdst": false,
    "text": isRULocale() ? "(UTC+06:00) Астана" : "(UTC+06:00) Astana (Nur-Sultan)",
    "utc": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ]
  },
  {
    "value": "N. Central Asia Standard Time",
    "abbr": "NCAST",
    "offset": 7,
    "isdst": false,
    "text": isRULocale() ? "(UTC+07:00) Новосибирск" : "(UTC+07:00) Novosibirsk",
    "utc": ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"]
  },
  {
    "value": "North Asia Standard Time",
    "abbr": "NAST",
    "offset": 8,
    "isdst": false,
    "text":  isRULocale() ? "(UTC+08:00) Красноярск" : "(UTC+08:00) Krasnoyarsk",
    "utc": ["Asia/Krasnoyarsk"]
  },
  {
    "value": "Japan Standard Time",
    "abbr": "JST",
    "offset": 9,
    "isdst": false,
    "text": isRULocale() ? "(UTC+09:00) Осака, Саппоро, Токио" : "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "utc": ["Asia/Dili", "Asia/Jayapura", "Asia/Tokyo", "Etc/GMT-9", "Pacific/Palau"]
  },
  {
    "value": "Cen. Australia Standard Time",
    "abbr": "CAST",
    "offset": 9.5,
    "isdst": false,
    "text": isRULocale() ? "(UTC+09:30) Аделаида " : "(UTC+09:30) Adelaide",
    "utc": ["Australia/Adelaide", "Australia/Broken_Hill"]
  },
  {
    "value": "AUS Eastern Standard Time",
    "abbr": "AEST",
    "offset": 10,
    "isdst": false,
    "text": isRULocale() ? "(UTC+10:00) Канберра, Мельбурн , Сидней" : "(UTC+10:00) Canberra, Melbourne, Sydney",
    "utc": ["Australia/Melbourne", "Australia/Sydney"]
  },
  {
    "value": "Vladivostok Standard Time",
    "abbr": "VST",
    "offset": 11,
    "isdst": false,
    "text": isRULocale() ? "(UTC+11:00) Владивосток" : "(UTC+11:00) Vladivostok",
    "utc": ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"]
  },
  {
    "value": "New Zealand Standard Time",
    "abbr": "NZST",
    "offset": 12,
    "isdst": false,
    "text": isRULocale() ? "(UTC+12:00) Окленд, Веллингтон" : "(UTC+12:00) Auckland, Wellington",
    "utc": ["Antarctica/McMurdo", "Pacific/Auckland"]
  },
  {
    "value": "Samoa Standard Time",
    "abbr": "SST",
    "offset": 13,
    "isdst": false,
    "text": isRULocale() ? "(UTC+13:00) Самоа" : "(UTC+13:00) Samoa",
    "utc": ["Pacific/Apia"]
  }
]
